import React from 'react';
import { Box, Grid2 as Grid, Typography, IconButton } from '@mui/material';
import vectorBlack from '../../assets/svg/vectorBlack.svg'
import { useNavigate } from "react-router-dom";
import './footer.css';

const Footer = ({ onSectionClick }) => {
  const navigate = useNavigate();
  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  const handleClickSection = (section) => {
    if (section === "CONTACTO") {
      navigate('/contacto');
    } else {
      const sectionMap = {
        "VARIEDADES": "products",
        "RECETOPS": "recipes",
        "TOP EXPERIENCIAS": "experiences"
      };

      if (window.location.pathname !== '/') {
        navigate('/');
        setTimeout(() => {
          onSectionClick(sectionMap[section]);
        }, 100);
      } else {
        onSectionClick(sectionMap[section]);
      }
    }
  };

  return (
    <Box className="footer-container">
      <Grid container spacing={4} justifyContent="center" sx={{ marginTop: '80px' }}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h7" className="footer-title">ABOUT US</Typography>
          <Typography variant="body2" className="footer-text">
            El sabor de la botana más TOP 🔝 #PonteTop con Top-Top’s®. 🔥😎 <br /> COME BIEN
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h7" className="footer-title">EXPLORE</Typography>
          <ul className="footer-text" style={{ paddingTop: '18px' }}>
            <li onClick={() => handleClickSection("VARIEDADES")}>VARIEDADES</li>
            <li onClick={() => handleClickSection("RECETOPS")}>RECETOPS</li>
            <li onClick={() => handleClickSection("CONTACTO")}>CONTACTO</li>
          </ul>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h7" className="footer-title">GAMES</Typography>
          <ul className="footer-text" style={{ paddingTop: '18px' }}>
            <li onClick={() => handleClickSection("TOP EXPERIENCIAS")}>TOP EXPERIENCIAS</li>
          </ul>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h7" className="footer-title">CONTACT</Typography>
          <Typography variant="body2" className="footer-text">
            55 5836 3636<br />
            4 SUR 123, CIUDAD INDUSTRIAL TIZAYUCA, HIDALGO,<br />
            CÓDIGO POSTAL 43800, MÉXICO.
          </Typography>
        </Grid>
      </Grid>
      <Box className="footer-svg">
        <Box className="footer-content">
          <Typography className='text-author'>@2024 derechos reservados</Typography>
          <Box className="social-icons">
            <IconButton className="social-icon facebook" onClick={() => handleClick('https://www.facebook.com/toptops.mx?mibextid=LQQJ4d')} />
            {/*<IconButton className="social-icon tiktok" onClick={() => handleClick('https://www.tiktok.com/@top.tops.mx?_t=8p7alDYhH7e&_r=1')} />*/}
            <IconButton className="social-icon youtube" onClick={() => handleClick('https://www.youtube.com/@toptopsmx')} />
            <IconButton className="social-icon instagram" onClick={() => handleClick('https://www.instagram.com/toptops.mx/')} />
          </Box>
        </Box>
        <img src={vectorBlack} alt='' />
      </Box>
    </Box>
  );
};

export default Footer;
