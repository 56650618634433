import React, { useState } from "react";
import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';

// Import CSS for the slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";

import leftArrow from '../../assets/images/leftArrow.png';
import rightArrow from '../../assets/images/rightArrow.png';

const NextArrow = ({ onClick }) => {
  return (
    <div className="custom-arrow next-arrow" onClick={onClick}>
      <img src={rightArrow} alt="Next" />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="custom-arrow prev-arrow" onClick={onClick}>
      <img src={leftArrow} alt="Previous" />
    </div>
  );
};

function Carousel({ images, customClass = '', slidesToShow }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    centerMode: true,
    autoplay: false,
    autoplaySpeed: 4000,
    centerPadding: "60px",
    focusOnSelect: true,
    beforeChange: (current, next) => setCurrentSlide(next),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const handleClick = ({ index, path }) => {
    if (index === currentSlide) {
      // Navigates to the path only when user cicks on centered slide
      navigate(path);
    }
  };

  return (
    <Box className={`carousel ${customClass}`} sx={{ backgroundColor: "#00000000" }}>
      <Slider {...settings}>
        {images.map((image, index) => {
          const slideClass = ""
          return (
            <Box key={index} className={slideClass}>
              <img
                src={image.src}
                alt={`Slide ${index}`}
                className="carousel-image"
                onClick={() => handleClick({ index, path: image.route })}
              />
              <Typography
                variant="h3"
                align="center"
                className="caption-slide"
                sx={{ color: "#fff", marginTop: 2 }}
              >
                {image.caption}
              </Typography>
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
}

export default Carousel;
