import React from "react";
import { Box } from "@mui/material";
import Logo from '../../assets/images/Logo_Toptops.png';
import SvgLeft1 from '../../assets/svg/arrowLeft.svg'
import SvgRight1 from '../../assets/svg/arrowRight.svg'
import "./markfooter.css";

function MarkFooter() {

  return (
    <Box className="mark-footer">
      {/* SVGs del lado izquierdo */}
      <Box className="svg-container-left">
        <img src={SvgLeft1} alt="" />
        <img src={SvgLeft1} alt="" />
        <img className="arrowShadow" src={SvgLeft1} alt="" />
      </Box>
      <Box className="logo-container">
        <img src={Logo} alt="TopTop's" className="logo" />
      </Box>
      {/* SVGs del lado derecho */}
      <Box className="svg-container-right">
        <img src={SvgRight1} alt="" />
        <img src={SvgRight1} alt="" />
        <img className="arrowShadow" src={SvgRight1} alt="" />
      </Box>
    </Box>
  );
}

export default MarkFooter;
