import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid2 as Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { products as MockedProducts } from '../utils/utils';
import { recipes } from '../../Recipes/utils/utils'
import { useCurrentBreakpoint } from '../../../../utils/breakpoints';
import ParallaxImage from '../../../../components/ParallaxImage/parallax-image';
import { useEasterEgg } from '../../../../components/EasterEgg/easter-egg';
import ImageDivider from '../../../../components/Divider/divider';
import Progress from '../../../../components/Progress/progress';

import IconIngredients from '../../../../assets/images/icon_ingredients.png'
import IconTableNutri from '../../../../assets/images/icon_tableN.png'
import ArrowProduct from '../../../../assets/images/arrowSpice.png'
import SpicyLine from '../../../../assets/images/spicyLine.png';
import SpicyLine1 from '../../../../assets/images/spicyLine_1.png'
import SpicyLine2 from '../../../../assets/images/spicyLine_2.png'
import TriangleDotted from "../../../../assets/images/triangles/triangle_dotted_upLeft.png";
import ProductModal from '../modal';

import './product-details.css';

const ProductDetails = () => {
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState();
  const [currentRecipes, setCurrentRecipes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const breakpoint = useCurrentBreakpoint();

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ type: '', content: '' });
  const { handleToggleBackdrop } = useEasterEgg();

  const getCurrentRecipes = (recipesIDS) => {
    return recipes.filter(recipe => recipesIDS.includes(recipe.id));
  };

  const handleRecipeClick = (recipeId) => {
    navigate(`/recetas/receta-${recipeId}`);
  };

  const mockFetchProducts = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(MockedProducts);
      }, 800);
    });
  };

  useEffect(() => {
    mockFetchProducts().then((data) => {
      setProducts(data);
      setIsLoading(false)
    });
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const validProduct = products.find((product) => product.name.toLowerCase().replace(/\s+/g, '-') === id);
      if (!id || !validProduct) {
        navigate('/');
        return;
      }
      setCurrentProduct(validProduct);
      console.log("array de recetas", getCurrentRecipes(validProduct.recipes));
      setCurrentRecipes(getCurrentRecipes(validProduct.recipes));
    }
  }, [id, navigate, isLoading, products]);

  const getSpicyImage = () => {
    let spicyLine = SpicyLine;
    if (breakpoint === 'mobile') {
      return currentProduct.spicyLine === 'SpicyLine1' ? SpicyLine1 : SpicyLine2;
    }
    return spicyLine;
  };

  if (!currentProduct) {
    return <Progress />;
  }

  const getImageProducts = () => {
    if (breakpoint === 'mobile') {
      return products.filter((product) => product.spicyLine === currentProduct.spicyLine);
    }
    return products;
  }

  const openModal = (type, content) => {
    setModalContent({ type, content });
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const handleImageClick = (index) => {
    handleToggleBackdrop(index);
  };

  return (
    <Box className="product-detail container">
      <Box className="product-detail-section" sx={{ backgroundImage: `url(${currentProduct.background})` }}>
        <Box className="icon-section">
          <img src={IconIngredients} alt="Ingredientes" className="icon-modal" onClick={() => openModal('nutritional', currentProduct.nutritionalTable)} />
          <img src={IconTableNutri} alt="Tabla Nutrimental" className="icon-modal" onClick={() => openModal('ingredients', currentProduct.ingredients)} />
        </Box>
        <Grid container spacing={2} className="product-content">
          {/* Sección de detalles del producto */}
          <Grid item xs={12} md={6} className="details-section">
            <Typography variant="h3" className='product-title'>
              {currentProduct.name}
            </Typography>
            <Typography variant="body1" className="product-spice">
              {currentProduct.subtitle}
            </Typography>
            <Typography variant="h3" className="product-subtitle">
              Descripción
            </Typography>
            <Typography variant="body1" className="product-description">
              {currentProduct.description}
            </Typography>
            {/* Recetario - Renderizado Condicional */}
            {currentRecipes && currentRecipes.length > 0 ? (
              <Grid item xs={12} sm={6} md={3} className="recipes-section">
                <Typography variant="h3" className="product-subtitle">
                  Recetario
                </Typography>
                <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                  {currentRecipes.map((recipe, index) => (
                    <li key={index} style={{ marginBottom: '14px', cursor: 'pointer' }} onClick={() => handleRecipeClick(recipe.id)}>
                      {recipe.name}
                    </li>
                  ))}
                </ul>
              </Grid>
            ) : (
              <Typography variant="body2" className="no-recipes-message">
                No hay recetas disponibles.
              </Typography>
            )}
          </Grid>
          {/* Sección de imagen */}
          <Grid item xs={12} md={6} className="imageProd-section">
            <img src={currentProduct.image} alt="Salsa Verde" className="product-image" />
          </Grid>
        </Grid>
      </Box>
      <Box className="spice-level-section">
        <ParallaxImage
          containerClass="right bottom"
          imgClass="triangle-center zoom"
          imageSrc={TriangleDotted}
          strength={0.01}
          lerpEase={0.10}
          mouseEffect={true}
          handleClick={() => handleImageClick(2)}
        />
        <Grid container spacing={2} className="image-grid">
          {getImageProducts().map((product, index) => (
            <Grid item xs={4} md={2} key={index} className="image-item">
              <Box className="image-wrapper">
                <img src={product.imageSlide} alt={`Imagen ${index + 1}`} className="main-image" />
                {currentProduct.name === product.name && <img src={ArrowProduct} alt="Flecha" className="arrow-image" />}
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box className="spicyLine-container">
          <img src={getSpicyImage()} alt="Nivel de Picante" className="spicyLine" />
        </Box>
      </Box>
      <ProductModal
        open={isModalOpen}
        handleClose={closeModal}
        type={modalContent.type}
        content={modalContent.content}
      />
      <ImageDivider />
    </Box>

  );
};

export default ProductDetails;
