import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { Backdrop, Box, Typography } from "@mui/material";
import { createjs, AdobeAn } from './animation.js';

import "./easter-egg.css";
import { getRandomMessage } from "./utils/utils.js";

const EasterEggContext = createContext();

export const useEasterEgg = () => {
  return useContext(EasterEggContext);
};

export const EasterEggProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [foundElements, setFoundElements] = useState([]);
  const [message, setMessage] = useState('');

  const handleToggleBackdrop = (idElem) => {
    if (Number.isInteger(idElem)) {
      if (foundElements.includes(idElem)) return;
      const newFoundElms = [...foundElements, idElem];
      const randomMessage = getRandomMessage(newFoundElms.length);
      setMessage(randomMessage);
      setFoundElements(newFoundElms);
    };

    setOpen((prev) => !prev);
  };

  return (
    <EasterEggContext.Provider value={{ open, handleToggleBackdrop, foundElements }}>
      {children}
      <EasterEgg open={open} handleClose={handleToggleBackdrop} message={message} />
    </EasterEggContext.Provider>
  );
};

function EasterEgg({ open, handleClose, message }) {
  const canvasRef = useRef(null);
  const exportRootRef = useRef(null);
  const stageRef = useRef(null);
  const animContainerRef = useRef(null);
  const domOverlayContainerRef = useRef(null);
  const [isCanvasReady, setIsCanvasReady] = useState(false);

  useEffect(() => {
    if (canvasRef.current) {
      setIsCanvasReady(true);
    }
  }, [canvasRef]);

  useEffect(() => {
    if (open && isCanvasReady) {
      const initAnimation = () => {
        if (!canvasRef.current || !canvasRef.current.getContext) return;

        const canvas = canvasRef.current;
        const comp = AdobeAn.getComposition("338CE7D82FBBC645819532142EF6C24D");
        const lib = comp.getLibrary();

        const handleComplete = (evt) => {
          if (!canvasRef.current || !canvasRef.current.getContext) {
            console.error("Canvas not initialized");
            return;
          }

          const queue = evt.target;
          const ssMetadata = lib.ssMetadata;

          for (let i = 0; i < ssMetadata.length; i++) {
            const ss = comp.getSpriteSheet();
            ss[ssMetadata[i].name] = new createjs.SpriteSheet({
              images: [queue.getResult(ssMetadata[i].name)],
              frames: ssMetadata[i].frames,
            });
          }

          exportRootRef.current = new lib.toptop();
          stageRef.current = new lib.Stage(canvas);

          stageRef.current.addChild(exportRootRef.current);

          createjs.Ticker.framerate = lib.properties.fps;
          createjs.Ticker.addEventListener("tick", stageRef.current);

          if (canvas && stageRef.current && animContainerRef.current && domOverlayContainerRef.current) {
            AdobeAn.makeResponsive(false, 'both', false, 1, [canvas, animContainerRef.current, domOverlayContainerRef.current]);
          }
          AdobeAn.compositionLoaded(lib.properties.id);
        };

        const loader = new createjs.LoadQueue(false);
        loader.addEventListener("fileload", (evt) => {
          const images = comp.getImages();
          if (evt && evt.item.type === "image") {
            images[evt.item.id] = evt.result;
          }
        });
        loader.addEventListener("complete", handleComplete);
        loader.loadManifest(lib.properties.manifest);
      };

      initAnimation();

      return () => {
        createjs.Ticker.removeAllEventListeners();
      };
    }
  }, [open, isCanvasReady]);
  return (
    <Backdrop className="easter-egg" open={open} onClick={handleClose}>
      <Box
        sx={{
          position: 'relative',
          width: '80%',
          paddingTop: '48.25%',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <canvas
          ref={canvasRef}
          width={1280}
          height={800}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />

        <Box
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            textAlign: 'center'
          }}
        >
          <Typography className="easter-egg_title">
            {message.text}
          </Typography>
        </Box>
        <Typography className="easter-egg_subtitle">
          {message.total}
        </Typography>
      </Box>
    </Backdrop>
  );
}

export default EasterEgg;
