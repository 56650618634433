import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import ParallaxImage from "../ParallaxImage/parallax-image.jsx";
import { createjs as CreateBowl, AdobeAn as Adobe } from './animation.js';


import Triangle from "../../assets/images/triangles/triangle_shadow_right.png";
import "./animated-bowl.css";
import ImageDivider from "../Divider/divider.jsx";



function AnimatedBowl() {
  const canvasRef = useRef(null);
  const exportRootRef = useRef(null);
  const stageRef = useRef(null);
  const animContainerRef = useRef(null);
  const domOverlayContainerRef = useRef(null);
  const [isCanvasReady, setIsCanvasReady] = useState(false);
  const intervalTime = 3500;

  const initAnimation = () => {
    if (!canvasRef.current || !canvasRef.current.getContext) return;

    const canvas = canvasRef.current;
    const comp = Adobe.getComposition("F3B90F06AE6B7845A837453449D887AA");
    const lib = comp.getLibrary();

    const handleComplete = (evt) => {
      if (!canvasRef.current || !canvasRef.current.getContext) {
        console.error("Canvas not initialized");
        return;
      }

      const queue = evt.target;
      const ssMetadata = lib.ssMetadata;

      for (let i = 0; i < ssMetadata.length; i++) {
        const ss = comp.getSpriteSheet();
        ss[ssMetadata[i].name] = new CreateBowl.SpriteSheet({
          images: [queue.getResult(ssMetadata[i].name)],
          frames: ssMetadata[i].frames,
        });
      }

      exportRootRef.current = new lib.tazon();
      stageRef.current = new lib.Stage(canvas);
      stageRef.current.addChild(exportRootRef.current);

      CreateBowl.Ticker.framerate = lib.properties.fps;
      CreateBowl.Ticker.addEventListener("tick", stageRef.current);

      if (canvas && stageRef.current && animContainerRef.current && domOverlayContainerRef.current) {
        Adobe.makeResponsive(false, 'both', false, 1, [canvas, animContainerRef.current, domOverlayContainerRef.current]);
      }
      Adobe.compositionLoaded(lib.properties.id);

      exportRootRef.current.on("tick", () => {
        if (exportRootRef.current.currentFrame === exportRootRef.current.totalFrames - 1) {
          setTimeout(() => {
            exportRootRef.current.gotoAndPlay(0);
          }, intervalTime);
        }
      });
    };

    const loader = new CreateBowl.LoadQueue(false);
    loader.addEventListener("fileload", (evt) => {
      const images = comp.getImages();
      if (evt && evt.item.type === "image") {
        images[evt.item.id] = evt.result;
      }
    });
    loader.addEventListener("complete", handleComplete);
    loader.loadManifest(lib.properties.manifest);
  };

  useEffect(() => {
    if (canvasRef.current) {
      setIsCanvasReady(true);
    }
  }, [canvasRef]);

  useEffect(() => {
    if (isCanvasReady) {

      initAnimation();

      return () => {
        CreateBowl.Ticker.removeAllEventListeners();
      };
    }
  }, [isCanvasReady]);

  return (
    <Box className="animated-bowl-container">
      <ParallaxImage containerClass="right" imgClass="triangle-right" strength={-0.25} lerpEase={0.10} imageSrc={Triangle} />
      <Box
        sx={{
          position: 'relative',
          width: '80%',
          paddingTop: '48.25%',
        }}
      >
        <canvas
          ref={canvasRef}
          width={1280}
          height={800}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />

        <Box
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            textAlign: 'center'
          }}
        >
        </Box>
      </Box>
      <ImageDivider />
    </Box>
  );
}

export default AnimatedBowl;
