export const recipes = [
  {
    id: 1,
    name: "Top Top’s® con queso asado",
    video:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/videos/01_TOP+TOPS+QUESO+ASADO_ALTA.mp4",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/images/01_TOP_TOPS_QUESO_ASADO.png",
    description:
      "¡Vas a elevar al 100 la botana del día, cuando prepares unos Top Top’s con queso asado! 🌟😋 ¡Una combinación fuera de la norma! ¿List@ para una explosión de sabor? ¡Mira el video y hazlo tú mism@! #TopTops #PonteTop",
    duration: "15 min",
    portions: "4 porciones",
    difficulty: "Media",
    ingredients: [
      "Queso panela 500g",
      "Top Top’s® Salsa negra 2 bolsas",
      "Cebolla morada encurtida ½ taza",
      "Chile serrano en rodajas 2 pzas.",
      "Pimiento rojo picado ½ pza.",
    ],
    preparation: [
      "Rebanar el queso panela y asar por ambos lados cada pieza.",
      "Servir en un platón una cama de Top Top’s Salsa negra y encima las rebanadas de queso panela asado.",
      "Preparar con cebolla morada encurtida, rodajas de chile y cubitos de pimiento.",
      "Servir. ",
    ],
  },
  {
    id: 2,
    name: "Chilaquiles Top Top’s®",
    video:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/videos/02_CHILAQUILES+TOP+TOPS_ALTA.mp4",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/images/02_CHILAQUILES_TOP_TOPS.png",
    description:
      "¿Qué tal te caerían unos chilaquilitos, con un extra muy especial? 🌄✨ ¡la combinación ideal de Top Top’s® Nacho crujientes, queso derretido + la salsa perfecta! Acá te enseñamos a hacer este desayuno de campeon@s #TopTops #PonteTop",
    duration: "15 min",
    portions: "4",
    difficulty: "Media",
    ingredients: [
      "Salsa verde 2 tazas",
      "Top Top’s® Nacho 2 bolsas",
      "Pollo cocido deshebrado 2 tazas",
      "Crema ½ taza",
      "Queso fresco desmoronado ½ taza",
      "Cebolla morada fileteada ¼ pza",
    ],
    preparation: [
      "Calentar en una olla la salsa verde.",
      "Colocar Top Top’s Nacho en platos hondos y salsear con la salsa verde caliente.",
      "Preparar con pollo deshebrado, crema, queso y cebolla morada al gusto.",
    ],
  },
  {
    id: 3,
    name: "Fondue Top Top’s®",
    video:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/videos/03_FONDUE+CON+TOP+TOPS_ALTA.mp4",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/images/03_FONDUE_CON_TOP_TOPS.png",
    description:
      "Las mejores cosas de la vida llevan queso encima 🧀🎉 ¡Sumerge en el queso derretido con unos ricos Top Top’s® salsa verde! Mira el video y transforma tu snack en una experiencia gourmet. #TopTops #PonteTop",
    duration: "25min",
    portions: "4",
    difficulty: "Media",
    ingredients: [
      "Mix de quesos rallados 4 tazas",
      "Leche 1 taza",
      "Chorizo frito 1 taza",
      "Cilantro picado 4 cdas",
      "Top Top’s® salsa verde 2 bolsas",
    ],
    preparation: [
      "Agregar el mix de quesos y la leche en una olla, -calentar hasta fundir.",
      "Incorporar el chorizo frito, mezclar y espolvorear con cilantro picado.",
      "Acompañar con Top Top’s® salsa verde para dippear con el fondue caliente. ",
    ],
  },
  {
    id: 4,
    name: "Salchichas al chipotle con Top Top’s®",
    video:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/videos/04_SALCHICHAS+AL+CHIPOTLE+CON+TOP+TOPS_ALTA.mp4",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/images/04_SALCHICHAS_AL_CHIPOTLE_CON_TOP_TOPS.png",
    description:
      "¿Antojito de algo picante? ¡Ármate estas Salchichas al Chipotle con Top-Top’s® Hot Chili! 🌶️🔥. Mira el video y disfruta de la combi completa y más Top. #TopTops #PonteTop",
    duration: "15min",
    portions: "4",
    difficulty: "Media",
    ingredients: [
      "Salchichas de cerdo 6 pzas",
      "Aceite vegetal 3 cdas",
      "Cebolla fileteada ¼ pza",
      "Puré de chipotle ¼ taza",
      "Crema ácida ¼ taza",
      "Top Top’s Hot chili 2 bolsas",
      "Pepinillos en rodajas ¼ taza",
      "Hojas de cilantro al gusto",
    ],
    preparation: [
      "Rebanar las salchichas de cerdo, reservar.",
      "En un sartén caliente con aceite para freír las salchichas.",
      "Incorporar el puré de chipotle con la crema, mezclar y cocinar a fuego bajo durante 5 min.",
      "Servir en un platón una cama de Top Top’s Hot chili, servir las salchichas al chipotle calientes, cebolla fileteada y pepinillos.",
      "Decorar con hojas de cilantro y servir. ",
    ],
  },
  {
    id: 5,
    name: "Tostadas de aguachile Top Top’s®",
    video:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/videos/05_TOSTADAS+DE+AGUACHILE_ALTA.mp4",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/images/05_TOSTADAS_DE_AGUACHILE.png",
    description:
      "🌿🌶️ ¡La forma más fresh de combinar Top Top’s® Salsa negra con un aguachile de 10! Mira el video y prepárate para saborear esta delicia. #TopTops #PonteTop",
    duration: "20min",
    portions: "4",
    difficulty: "Media",
    ingredients: [
      "Camarón pacotilla 2 tazas",
      "Salsa verde 2 tazas",
      "Jugo de limón 1 taza",
      "Rábano rebanado 2 pzas",
      "Cebolla morada fileteada ¼ pza",
      "Chile serrano en rodajas 2 pzas",
      "Top Top’s® Salsa negra 1 bolsa",
      "Cilantro picado 2 cdas",
    ],
    preparation: [
      "En un recipiente, marinar los camarones con el jugo de limón y la salsa verde.",
      "Agregar el rábano, cebolla morada, y  rodajas de chile serrano. Mezclar.",
      "Servir en un plato y hacer tostadas con los Top Top’s® Salsa negra.",
      "Espolvorear con cilantro picado al gusto.",
    ],
  },
  {
    id: 6,
    name: "Top Top’s® cacerola",
    video:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/videos/06_TOP+TOPS+CACEROLA_ALTA.mp4",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/images/06_TOP_TOPS_CACEROLA.png",
    description:
      "¡Disfruta de la mezcla de quesos fundidos y carne deshebrada sobre Top Top’s® Salsa verde crujientes! 🧀🍖 Mira el video y dale una vuelta a tu cena de hoy. #TopTops #PonteTop",
    duration: "15min",
    portions: "4",
    difficulty: "Media",
    ingredients: [
      "Top Top’s® Salsa verde 2 bolsas",
      "Mix de quesos 1 ½ tazas",
      "Carne de res deshebrada 1 taza",
      "Cebolla picada ¼ pza",
      "Jitomate picado 1 pza",
      "Chile serrano picado 1 pza",
    ],
    preparation: [
      "Servir un refractario de Top Top’s® salsa verde.",
      "Sobre un sartén caliente, freír la carne de res.",
      "En el refractario de Top top’s ®, añadir la carne deshebrada frita, mix de quesos. Gratinar en el horno a 180°C durante 10 min. ",
      "Servir caliente y agregar cebolla, jitomate y chile serrano.",
    ],
  },
  {
    id: 7,
    name: "Porkbelly BBQ Top Top’s®",
    video:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/videos/09_PORK+BELLY+BBQ+CON+TOP+TOPS_ALTA.mp4",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/images/09_PORK_BELLY_BBQ_CON_TOP_TOPS.png",
    description:
      "¡Uffas el resultado de combinar Top Top’s® Hot Chili junto con carnita BBQ! 🍖🔥Mira el video y convierte tu snack en un festín de lo más top. #TopTops #PonteTop",
    duration: "15min",
    portions: "4",
    difficulty: "Media",
    ingredients: [
      "Aceite vegetal 3 cdas",
      "Pork belly deshebrado 2 tazas",
      "Salsa BBQ ½ taza",
      "Frijoles negros enteros 1 taza ",
      "Top Top’s® Hot chili 2 bolsas",
      "Chiles Nachos de jalapeño La Costeña® ¼ taza",
      "Salsa de queso amarillo ¼ taza",
    ],
    preparation: [
      "En un sartén, agregar pork belly y salsa bbq a fuego bajo.",
      "Sobre otro recipiente, calentar los frijoles negros y reservar. ",
      "Servir en un plato o refractario los Top Top’s® Hot chili, añadir pork belly BBQ y frijoles negros. ",
      "Servimos con los chiles nachos de jalapeño La Costeña®  y salsa de queso.",
    ],
  },
  {
    id: 8,
    name: "Sándwich crujiente Top Top’s®",
    video:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/videos/07_SA%CC%81NDWICH+CRUJIENTE_ALTA.mp4",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/images/10_TOP_TOPS_CHEESE.png",
    description:
      "¡Un humilde sándwichito, se transforma en un titán de sabor al añadirle un toque extra de Top Top’s® Hot chili! el candidato ideal del munchies. Dale play al video y haz de tu sándwich una experiencia irresistible. #TopTops #PonteTop",
    duration: "15min",
    portions: "4",
    difficulty: "Media",
    ingredients: [
      "Huevo 4 pzas",
      "Pan de caja brioche 8 rebanadas",
      "Mix de quesos rallados 2 tazas",
      "Top Top’s® Hot chili 2 bolsas ",
      "Nachos de jalapeño ¼ taza",
    ],
    preparation: [
      "Batir en un recipiente las piezas de huevo, reservar.",
      "Triturar los Top Top’s® Hot chili, reservar. ",
      "Preparar los sándwiches con el mix de quesos. ",
      "Sumergir el sándwich en la mezcla de huevo y empanizar ambas tapas con Top Top’s® triturados. ",
      "Meter cada sándwich en la airfryer y cocinar a 180°C durante 4 min. ",
      "Servir caliente y acompañar con nachos de jalapeño. ",
    ],
  },
  {
    id: 9,
    name: "Top Top’s® Cheese",
    video:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/videos/10_TOP+TOPS+CHEESE_ALTA.mp4",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/images/07_SANDWICH_CRUJIENTE.png",
    description:
      "Añádele un +10 de sabor a tu día con un mix de Top Top’s® Hot chili y Salsa negra + queso derretido 🧀🌟 ¡Un festín de sabor! #TopTops #PonteTop",
    duration: "20min",
    portions: "4",
    difficulty: "Media",
    ingredients: [
      "Top Top’s® Hot chili 2 bolsas",
      "Top Top’s® Salsa negra 2 bolsas",
      "Queso manchego en trozos 500g",
      "Harina 1 taza",
      "Huevo 4 pzas",
      "Zanahoria en bastones 2 pzas",
      "Pepino en bastones 1 pza",
      "Jícama en bastones ½ pza",
    ],
    preparation: [
      "Crear un mix de Top Top’s® Hot Chili y Salsa negra. Triturarlos y reservar ",
      "Hacer triángulos de queso manchego",
      "Pasar cada triángulo por harina, luego por el huevo batido y empanizar con los Top Top’s® triturados. ",
      "Meter los triángulos de queso empanizados en la airfryer y cocinar a 180°C durante 3 min. ",
      "Servir calientes y acompañar con los bastones de zanahoria, pepino y jícama. ",
    ],
  },
  {
    id: 10,
    name: "Hamburguesa ahogada en Top Top’s® ",
    video:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/videos/08_HAMBURGUESA+AHOGADA_ALTA.mp4",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Recetas/images/08_HAMBURGUESA_AHOGADA.png",
    description:
      "Lleva tus tardes de hamburguesas a otro nivel con extra de queso y Top-Top’s® Nachos. Dale play al video y #PonteTop  ",
    duration: "30min",
    portions: "6",
    difficulty: "Media",
    ingredients: [
      "Top Top’s® Nacho 2 bolsas",
      "Pan para hamburguesa 1 bolsa",
      "Carne para hamburguesa 1 Paquete",
      "Queso para nachos 1 Tza",
      "Queso americano 1 Sobre",
      "Cebolla morada 1 pieza",
      "Pepinillos 1 frasco",
      "Mayonesa 1 frasco",
    ],
    preparation: [
      "En una cazuela, se calienta el queso y Top Tops®  Nacho triturados, reservar. ",
      "Untar el pan para hamburguesa con mayonesa y añadir la carne de hamburguesa ya cocinada, con queso y cebolla. ",
      "Agrega Top Tops® Nacho en la parte de arriba, añadir pepinillos y colocar la tapa superior del pan",
      "Inunda del queso derretido, la hamburguesa, mientras siga caliente. ",
      "Disfruta. ",
    ],
  },
];
