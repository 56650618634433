const createjs = window.createjs || {};
const AdobeAn = window.AdobeAn || {};
let exportRoot, stage;

(function (cjs, an) {
  var p; // shortcut to reference prototypes
  var lib = {};
  var ss = {};
  var img = {};
  lib.ssMetadata = [
    {
      name: "tazon_atlas_1",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_2",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_3",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_4",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_5",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_6",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_7",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_8",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_9",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_10",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_11",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_12",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_13",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_14",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_15",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_16",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_17",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_18",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_19",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_20",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_21",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_22",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_23",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_24",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_25",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_26",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_27",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_28",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_29",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_30",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_31",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_32",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_33",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_34",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_35",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_36",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    {
      name: "tazon_atlas_37",
      frames: [
        [0, 0, 1280, 800],
        [0, 802, 1280, 800],
      ],
    },
    { name: "tazon_atlas_38", frames: [[0, 0, 1280, 800]] },
  ];

  (lib.AnMovieClip = function () {
    this.actionFrames = [];
    this.ignorePause = false;
    this.gotoAndPlay = function (positionOrLabel) {
      cjs.MovieClip.prototype.gotoAndPlay.call(this, positionOrLabel);
    };
    this.play = function () {
      cjs.MovieClip.prototype.play.call(this);
    };
    this.gotoAndStop = function (positionOrLabel) {
      cjs.MovieClip.prototype.gotoAndStop.call(this, positionOrLabel);
    };
    this.stop = function () {
      cjs.MovieClip.prototype.stop.call(this);
    };
  }).prototype = p = new cjs.MovieClip();
  // symbols:

  (lib.Comp1_0 = function () {
    this.initialize(ss["tazon_atlas_1"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_1 = function () {
    this.initialize(ss["tazon_atlas_1"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_10 = function () {
    this.initialize(ss["tazon_atlas_2"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_11 = function () {
    this.initialize(ss["tazon_atlas_2"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_12 = function () {
    this.initialize(ss["tazon_atlas_3"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_13 = function () {
    this.initialize(ss["tazon_atlas_3"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_14 = function () {
    this.initialize(ss["tazon_atlas_4"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_15 = function () {
    this.initialize(ss["tazon_atlas_4"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_16 = function () {
    this.initialize(ss["tazon_atlas_5"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_17 = function () {
    this.initialize(ss["tazon_atlas_5"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_18 = function () {
    this.initialize(ss["tazon_atlas_6"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_19 = function () {
    this.initialize(ss["tazon_atlas_6"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_2 = function () {
    this.initialize(ss["tazon_atlas_7"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_20 = function () {
    this.initialize(ss["tazon_atlas_7"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_21 = function () {
    this.initialize(ss["tazon_atlas_8"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_22 = function () {
    this.initialize(ss["tazon_atlas_8"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_23 = function () {
    this.initialize(ss["tazon_atlas_9"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_24 = function () {
    this.initialize(ss["tazon_atlas_9"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_25 = function () {
    this.initialize(ss["tazon_atlas_10"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_26 = function () {
    this.initialize(ss["tazon_atlas_10"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_27 = function () {
    this.initialize(ss["tazon_atlas_11"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_28 = function () {
    this.initialize(ss["tazon_atlas_11"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_29 = function () {
    this.initialize(ss["tazon_atlas_12"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_3 = function () {
    this.initialize(ss["tazon_atlas_12"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_30 = function () {
    this.initialize(ss["tazon_atlas_13"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_31 = function () {
    this.initialize(ss["tazon_atlas_13"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_32 = function () {
    this.initialize(ss["tazon_atlas_14"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_33 = function () {
    this.initialize(ss["tazon_atlas_14"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_34 = function () {
    this.initialize(ss["tazon_atlas_15"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_35 = function () {
    this.initialize(ss["tazon_atlas_15"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_36 = function () {
    this.initialize(ss["tazon_atlas_16"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_37 = function () {
    this.initialize(ss["tazon_atlas_16"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_38 = function () {
    this.initialize(ss["tazon_atlas_17"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_39 = function () {
    this.initialize(ss["tazon_atlas_17"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_4 = function () {
    this.initialize(ss["tazon_atlas_18"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_40 = function () {
    this.initialize(ss["tazon_atlas_18"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_41 = function () {
    this.initialize(ss["tazon_atlas_19"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_42 = function () {
    this.initialize(ss["tazon_atlas_19"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_43 = function () {
    this.initialize(ss["tazon_atlas_20"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_44 = function () {
    this.initialize(ss["tazon_atlas_20"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_45 = function () {
    this.initialize(ss["tazon_atlas_21"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_46 = function () {
    this.initialize(ss["tazon_atlas_21"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_47 = function () {
    this.initialize(ss["tazon_atlas_22"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_48 = function () {
    this.initialize(ss["tazon_atlas_22"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_49 = function () {
    this.initialize(ss["tazon_atlas_23"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_5 = function () {
    this.initialize(ss["tazon_atlas_23"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_50 = function () {
    this.initialize(ss["tazon_atlas_24"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_51 = function () {
    this.initialize(ss["tazon_atlas_24"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_52 = function () {
    this.initialize(ss["tazon_atlas_25"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_53 = function () {
    this.initialize(ss["tazon_atlas_25"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_54 = function () {
    this.initialize(ss["tazon_atlas_26"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_55 = function () {
    this.initialize(ss["tazon_atlas_26"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_56 = function () {
    this.initialize(ss["tazon_atlas_27"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_57 = function () {
    this.initialize(ss["tazon_atlas_27"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_58 = function () {
    this.initialize(ss["tazon_atlas_28"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_59 = function () {
    this.initialize(ss["tazon_atlas_28"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_6 = function () {
    this.initialize(ss["tazon_atlas_29"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_60 = function () {
    this.initialize(ss["tazon_atlas_29"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_61 = function () {
    this.initialize(ss["tazon_atlas_30"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_62 = function () {
    this.initialize(ss["tazon_atlas_30"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_63 = function () {
    this.initialize(ss["tazon_atlas_31"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_64 = function () {
    this.initialize(ss["tazon_atlas_31"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_65 = function () {
    this.initialize(ss["tazon_atlas_32"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_66 = function () {
    this.initialize(ss["tazon_atlas_32"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_67 = function () {
    this.initialize(ss["tazon_atlas_33"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_68 = function () {
    this.initialize(ss["tazon_atlas_33"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_69 = function () {
    this.initialize(ss["tazon_atlas_34"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_7 = function () {
    this.initialize(ss["tazon_atlas_34"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_70 = function () {
    this.initialize(ss["tazon_atlas_35"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_71 = function () {
    this.initialize(ss["tazon_atlas_35"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_72 = function () {
    this.initialize(ss["tazon_atlas_36"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_73 = function () {
    this.initialize(ss["tazon_atlas_36"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_74 = function () {
    this.initialize(ss["tazon_atlas_37"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_8 = function () {
    this.initialize(ss["tazon_atlas_37"]);
    this.gotoAndStop(1);
  }).prototype = p = new cjs.Sprite();

  (lib.Comp1_9 = function () {
    this.initialize(ss["tazon_atlas_38"]);
    this.gotoAndStop(0);
  }).prototype = p = new cjs.Sprite();

  // stage content:
  (lib.tazon = function (mode, startPosition, loop, reversed) {
    if (loop === null) {
      loop = true;
    }
    if (reversed === null) {
      reversed = false;
    }
    var props = {};
    props.mode = mode;
    props.startPosition = startPosition;
    props.labels = {};
    props.loop = loop;
    props.reversed = reversed;
    cjs.MovieClip.apply(this, [props]);

    // Capa_1
    this.instance = new lib.Comp1_0();

    this.instance_1 = new lib.Comp1_1();

    this.instance_2 = new lib.Comp1_2();

    this.instance_3 = new lib.Comp1_3();

    this.instance_4 = new lib.Comp1_4();

    this.instance_5 = new lib.Comp1_5();

    this.instance_6 = new lib.Comp1_6();

    this.instance_7 = new lib.Comp1_7();

    this.instance_8 = new lib.Comp1_8();

    this.instance_9 = new lib.Comp1_9();

    this.instance_10 = new lib.Comp1_10();

    this.instance_11 = new lib.Comp1_11();

    this.instance_12 = new lib.Comp1_12();

    this.instance_13 = new lib.Comp1_13();

    this.instance_14 = new lib.Comp1_14();

    this.instance_15 = new lib.Comp1_15();

    this.instance_16 = new lib.Comp1_16();

    this.instance_17 = new lib.Comp1_17();

    this.instance_18 = new lib.Comp1_18();

    this.instance_19 = new lib.Comp1_19();

    this.instance_20 = new lib.Comp1_20();

    this.instance_21 = new lib.Comp1_21();

    this.instance_22 = new lib.Comp1_22();

    this.instance_23 = new lib.Comp1_23();

    this.instance_24 = new lib.Comp1_24();

    this.instance_25 = new lib.Comp1_25();

    this.instance_26 = new lib.Comp1_26();

    this.instance_27 = new lib.Comp1_27();

    this.instance_28 = new lib.Comp1_28();

    this.instance_29 = new lib.Comp1_29();

    this.instance_30 = new lib.Comp1_30();

    this.instance_31 = new lib.Comp1_31();

    this.instance_32 = new lib.Comp1_32();

    this.instance_33 = new lib.Comp1_33();

    this.instance_34 = new lib.Comp1_34();

    this.instance_35 = new lib.Comp1_35();

    this.instance_36 = new lib.Comp1_36();

    this.instance_37 = new lib.Comp1_37();

    this.instance_38 = new lib.Comp1_38();

    this.instance_39 = new lib.Comp1_39();

    this.instance_40 = new lib.Comp1_40();

    this.instance_41 = new lib.Comp1_41();

    this.instance_42 = new lib.Comp1_42();

    this.instance_43 = new lib.Comp1_43();

    this.instance_44 = new lib.Comp1_44();

    this.instance_45 = new lib.Comp1_45();

    this.instance_46 = new lib.Comp1_46();

    this.instance_47 = new lib.Comp1_47();

    this.instance_48 = new lib.Comp1_48();

    this.instance_49 = new lib.Comp1_49();

    this.instance_50 = new lib.Comp1_50();

    this.instance_51 = new lib.Comp1_51();

    this.instance_52 = new lib.Comp1_52();

    this.instance_53 = new lib.Comp1_53();

    this.instance_54 = new lib.Comp1_54();

    this.instance_55 = new lib.Comp1_55();

    this.instance_56 = new lib.Comp1_56();

    this.instance_57 = new lib.Comp1_57();

    this.instance_58 = new lib.Comp1_58();

    this.instance_59 = new lib.Comp1_59();

    this.instance_60 = new lib.Comp1_60();

    this.instance_61 = new lib.Comp1_61();

    this.instance_62 = new lib.Comp1_62();

    this.instance_63 = new lib.Comp1_63();

    this.instance_64 = new lib.Comp1_64();

    this.instance_65 = new lib.Comp1_65();

    this.instance_66 = new lib.Comp1_66();

    this.instance_67 = new lib.Comp1_67();

    this.instance_68 = new lib.Comp1_68();

    this.instance_69 = new lib.Comp1_69();

    this.instance_70 = new lib.Comp1_70();

    this.instance_71 = new lib.Comp1_71();

    this.instance_72 = new lib.Comp1_72();

    this.instance_73 = new lib.Comp1_73();

    this.instance_74 = new lib.Comp1_74();

    this.timeline.addTween(
      cjs.Tween.get({})
        .to({ state: [{ t: this.instance }] })
        .to({ state: [{ t: this.instance_1 }] }, 1)
        .to({ state: [{ t: this.instance_2 }] }, 1)
        .to({ state: [{ t: this.instance_3 }] }, 1)
        .to({ state: [{ t: this.instance_4 }] }, 1)
        .to({ state: [{ t: this.instance_5 }] }, 1)
        .to({ state: [{ t: this.instance_6 }] }, 1)
        .to({ state: [{ t: this.instance_7 }] }, 1)
        .to({ state: [{ t: this.instance_8 }] }, 1)
        .to({ state: [{ t: this.instance_9 }] }, 1)
        .to({ state: [{ t: this.instance_10 }] }, 1)
        .to({ state: [{ t: this.instance_11 }] }, 1)
        .to({ state: [{ t: this.instance_12 }] }, 1)
        .to({ state: [{ t: this.instance_13 }] }, 1)
        .to({ state: [{ t: this.instance_14 }] }, 1)
        .to({ state: [{ t: this.instance_15 }] }, 1)
        .to({ state: [{ t: this.instance_16 }] }, 1)
        .to({ state: [{ t: this.instance_17 }] }, 1)
        .to({ state: [{ t: this.instance_18 }] }, 1)
        .to({ state: [{ t: this.instance_19 }] }, 1)
        .to({ state: [{ t: this.instance_20 }] }, 1)
        .to({ state: [{ t: this.instance_21 }] }, 1)
        .to({ state: [{ t: this.instance_22 }] }, 1)
        .to({ state: [{ t: this.instance_23 }] }, 1)
        .to({ state: [{ t: this.instance_24 }] }, 1)
        .to({ state: [{ t: this.instance_25 }] }, 1)
        .to({ state: [{ t: this.instance_26 }] }, 1)
        .to({ state: [{ t: this.instance_27 }] }, 1)
        .to({ state: [{ t: this.instance_28 }] }, 1)
        .to({ state: [{ t: this.instance_29 }] }, 1)
        .to({ state: [{ t: this.instance_30 }] }, 1)
        .to({ state: [{ t: this.instance_31 }] }, 1)
        .to({ state: [{ t: this.instance_32 }] }, 1)
        .to({ state: [{ t: this.instance_33 }] }, 1)
        .to({ state: [{ t: this.instance_34 }] }, 1)
        .to({ state: [{ t: this.instance_35 }] }, 1)
        .to({ state: [{ t: this.instance_36 }] }, 1)
        .to({ state: [{ t: this.instance_37 }] }, 1)
        .to({ state: [{ t: this.instance_38 }] }, 1)
        .to({ state: [{ t: this.instance_39 }] }, 1)
        .to({ state: [{ t: this.instance_40 }] }, 1)
        .to({ state: [{ t: this.instance_41 }] }, 1)
        .to({ state: [{ t: this.instance_42 }] }, 1)
        .to({ state: [{ t: this.instance_43 }] }, 1)
        .to({ state: [{ t: this.instance_44 }] }, 1)
        .to({ state: [{ t: this.instance_45 }] }, 1)
        .to({ state: [{ t: this.instance_46 }] }, 1)
        .to({ state: [{ t: this.instance_47 }] }, 1)
        .to({ state: [{ t: this.instance_48 }] }, 1)
        .to({ state: [{ t: this.instance_49 }] }, 1)
        .to({ state: [{ t: this.instance_50 }] }, 1)
        .to({ state: [{ t: this.instance_51 }] }, 1)
        .to({ state: [{ t: this.instance_52 }] }, 1)
        .to({ state: [{ t: this.instance_53 }] }, 1)
        .to({ state: [{ t: this.instance_54 }] }, 1)
        .to({ state: [{ t: this.instance_55 }] }, 1)
        .to({ state: [{ t: this.instance_56 }] }, 1)
        .to({ state: [{ t: this.instance_57 }] }, 1)
        .to({ state: [{ t: this.instance_58 }] }, 1)
        .to({ state: [{ t: this.instance_59 }] }, 1)
        .to({ state: [{ t: this.instance_60 }] }, 1)
        .to({ state: [{ t: this.instance_61 }] }, 1)
        .to({ state: [{ t: this.instance_62 }] }, 1)
        .to({ state: [{ t: this.instance_63 }] }, 1)
        .to({ state: [{ t: this.instance_64 }] }, 1)
        .to({ state: [{ t: this.instance_65 }] }, 1)
        .to({ state: [{ t: this.instance_66 }] }, 1)
        .to({ state: [{ t: this.instance_67 }] }, 1)
        .to({ state: [{ t: this.instance_68 }] }, 1)
        .to({ state: [{ t: this.instance_69 }] }, 1)
        .to({ state: [{ t: this.instance_70 }] }, 1)
        .to({ state: [{ t: this.instance_71 }] }, 1)
        .to({ state: [{ t: this.instance_72 }] }, 1)
        .to({ state: [{ t: this.instance_73 }] }, 1)
        .to({ state: [{ t: this.instance_74 }] }, 1)
        .wait(1)
    );

    this._renderFirstFrame();
  }).prototype = p = new lib.AnMovieClip();
  p.nominalBounds = new cjs.Rectangle(640, 400, 640, 400);
  // library properties:
  lib.properties = {
    id: "F3B90F06AE6B7845A837453449D887AA",
    width: 1280,
    height: 800,
    fps: 40,
    color: "#FFFFFF",
    opacity: 1.0,
    manifest: [
      {
        src: `${window.location.origin}/images/tazon_atlas_1.png?1730066428668`,
        id: "tazon_atlas_1",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_2.png?1730066428668`,
        id: "tazon_atlas_2",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_3.png?1730066428668`,
        id: "tazon_atlas_3",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_4.png?1730066428668`,
        id: "tazon_atlas_4",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_5.png?1730066428668`,
        id: "tazon_atlas_5",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_6.png?1730066428670`,
        id: "tazon_atlas_6",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_7.png?1730066428670`,
        id: "tazon_atlas_7",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_8.png?1730066428670`,
        id: "tazon_atlas_8",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_9.png?1730066428670`,
        id: "tazon_atlas_9",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_10.png?1730066428670`,
        id: "tazon_atlas_10",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_11.png?1730066428670`,
        id: "tazon_atlas_11",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_12.png?1730066428670`,
        id: "tazon_atlas_12",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_13.png?1730066428671`,
        id: "tazon_atlas_13",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_14.png?1730066428671`,
        id: "tazon_atlas_14",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_15.png?1730066428671`,
        id: "tazon_atlas_15",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_16.png?1730066428671`,
        id: "tazon_atlas_16",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_17.png?1730066428671`,
        id: "tazon_atlas_17",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_18.png?1730066428671`,
        id: "tazon_atlas_18",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_19.png?1730066428671`,
        id: "tazon_atlas_19",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_20.png?1730066428671`,
        id: "tazon_atlas_20",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_21.png?1730066428671`,
        id: "tazon_atlas_21",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_22.png?1730066428671`,
        id: "tazon_atlas_22",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_23.png?1730066428671`,
        id: "tazon_atlas_23",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_24.png?1730066428671`,
        id: "tazon_atlas_24",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_25.png?1730066428672`,
        id: "tazon_atlas_25",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_26.png?1730066428672`,
        id: "tazon_atlas_26",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_27.png?1730066428672`,
        id: "tazon_atlas_27",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_28.png?1730066428672`,
        id: "tazon_atlas_28",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_29.png?1730066428672`,
        id: "tazon_atlas_29",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_30.png?1730066428672`,
        id: "tazon_atlas_30",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_31.png?1730066428672`,
        id: "tazon_atlas_31",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_32.png?1730066428672`,
        id: "tazon_atlas_32",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_33.png?1730066428672`,
        id: "tazon_atlas_33",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_34.png?1730066428672`,
        id: "tazon_atlas_34",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_35.png?1730066428672`,
        id: "tazon_atlas_35",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_36.png?1730066428672`,
        id: "tazon_atlas_36",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_37.png?1730066428672`,
        id: "tazon_atlas_37",
      },
      {
        src: `${window.location.origin}/images/tazon_atlas_38.png?1730066428673`,
        id: "tazon_atlas_38",
      },
    ],
    preloads: [],
  };

  // bootstrap callback support:

  (lib.Stage = function (canvas) {
    createjs.Stage.call(this, canvas);
  }).prototype = p = new createjs.Stage();

  p.setAutoPlay = function (autoPlay) {
    this.tickEnabled = autoPlay;
  };
  p.play = function () {
    this.tickEnabled = true;
    this.getChildAt(0).gotoAndPlay(this.getTimelinePosition());
  };
  p.stop = function (ms) {
    if (ms) this.seek(ms);
    this.tickEnabled = false;
  };
  p.seek = function (ms) {
    this.tickEnabled = true;
    this.getChildAt(0).gotoAndStop((lib.properties.fps * ms) / 1000);
  };
  p.getDuration = function () {
    return (this.getChildAt(0).totalFrames / lib.properties.fps) * 1000;
  };

  p.getTimelinePosition = function () {
    return (this.getChildAt(0).currentFrame / lib.properties.fps) * 1000;
  };

  an.bootcompsLoaded = an.bootcompsLoaded || [];
  if (!an.bootstrapListeners) {
    an.bootstrapListeners = [];
  }

  an.bootstrapCallback = function (fnCallback) {
    an.bootstrapListeners.push(fnCallback);
    if (an.bootcompsLoaded.length > 0) {
      for (var i = 0; i < an.bootcompsLoaded.length; ++i) {
        fnCallback(an.bootcompsLoaded[i]);
      }
    }
  };

  an.compositions = an.compositions || {};
  an.compositions["F3B90F06AE6B7845A837453449D887AA"] = {
    getStage: function () {
      return exportRoot.stage;
    },
    getLibrary: function () {
      return lib;
    },
    getSpriteSheet: function () {
      return ss;
    },
    getImages: function () {
      return img;
    },
  };

  an.compositionLoaded = function (id) {
    an.bootcompsLoaded.push(id);
    for (var j = 0; j < an.bootstrapListeners.length; j++) {
      an.bootstrapListeners[j](id);
    }
  };

  an.getComposition = function (id) {
    return an.compositions[id];
  };

  an.makeResponsive = function (
    isResp,
    respDim,
    isScale,
    scaleType,
    domContainers
  ) {
    var lastW,
      lastH,
      lastS = 1;
    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();
    function resizeCanvas() {
      var w = lib.properties.width,
        h = lib.properties.height;
      var iw = window.innerWidth,
        ih = window.innerHeight;
      var pRatio = window.devicePixelRatio || 1,
        xRatio = iw / w,
        yRatio = ih / h,
        sRatio = 1;
      if (isResp) {
        if (
          (respDim === "width" && lastW === iw) ||
          (respDim === "height" && lastH === ih)
        ) {
          sRatio = lastS;
        } else if (!isScale) {
          if (iw < w || ih < h) sRatio = Math.min(xRatio, yRatio);
        } else if (scaleType === 1) {
          sRatio = Math.min(xRatio, yRatio);
        } else if (scaleType === 2) {
          sRatio = Math.max(xRatio, yRatio);
        }
      }
      domContainers[0].width = w * pRatio * sRatio;
      domContainers[0].height = h * pRatio * sRatio;
      domContainers.forEach(function (container) {
        container.style.width = w * sRatio + "px";
        container.style.height = h * sRatio + "px";
      });
      stage.scaleX = pRatio * sRatio;
      stage.scaleY = pRatio * sRatio;
      lastW = iw;
      lastH = ih;
      lastS = sRatio;
      stage.tickOnUpdate = false;
      stage.update();
      stage.tickOnUpdate = true;
    }
  };
  an.handleSoundStreamOnTick = function (event) {
    if (!event.paused) {
      var stageChild = stage.getChildAt(0);
      if (!stageChild.paused || stageChild.ignorePause) {
        stageChild.syncStreamSounds();
      }
    }
  };
  an.handleFilterCache = function (event) {
    if (!event.paused) {
      var target = event.target;
      if (target) {
        if (target.filterCacheList) {
          for (var index = 0; index < target.filterCacheList.length; index++) {
            var cacheInst = target.filterCacheList[index];
            if (
              cacheInst.startFrame <= target.currentFrame &&
              target.currentFrame <= cacheInst.endFrame
            ) {
              cacheInst.instance.cache(
                cacheInst.x,
                cacheInst.y,
                cacheInst.w,
                cacheInst.h
              );
            }
          }
        }
      }
    }
  };
})(createjs, AdobeAn);

export { createjs, AdobeAn, exportRoot, stage };
