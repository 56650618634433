export const products = [
  {
    name: "SALSA VERDE",
    description:
      "La frescura de ingredientes verdes y el sabor más TOP, se combinan para crear la botana ideal para reunirte con los reales.",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/salsa_verde.png",
    imageSlide:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Home/Productos/251024_TT-SALSA-VERDE.png",
    background:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/bg_salsa_verde.png",
    background_mobile: "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/bgm_salsa_verde.png",
    subtitle: "poco picante",
    spicyLine: "SpicyLine1",
    ingredients:
      "Harina de Maíz Nixtamalizado, Aceite Vegetal, Sazonador (Maltodextrina, Sal Yodada, Sólidos de la Leche, Tomate en Polvo, Proteína Vegetal Hidrolizada de Maíz, Glutamato Monosódico, Ácido Cítrico, Especias, Caseinato de Sodio, Almidón Modificado, Ácido Acético, Fostato de Potasio, Dióxido de Silicio, Gualinato de Sodio, Inosinato de Sodio, Saborizantes Naturales e Idénticos a los Naturales, Azul 1, Amarillo 5, Rojo 40), Rojo 40, Amarillo 5, Amarillo 6. Contiene Leche.",
    gramaje: "52g, 40g",
    nutritionalTable: {
      energetico_envase: [
        "52g 240 kcal (1002.5 kJ) por 100g",
        "40g 180 kcal (771.2kJ) por 100g",
      ],
      contenido_energetico: "460 kcal (1928 kJ)",
      proteinas: "6g",
      grasas_totales: "25g",
      grasas_saturadas: "11g",
      grasas_trans: "0mg",
      hidratos_carbono: "53g",
      azucares: "0g",
      azucar_anadido: "0g",
      fibra_dieta: "6g",
      sodio: "490mg",
    },
    recipes: [3, 6],
  },
  {
    name: "NACHOS",
    description:
      "Encuentra el sabor más TOP del queso en esta rica variedad que podrás combinar con muchas cosas más.",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/nachos.png",
    imageSlide:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Home/Productos/251024_TT-NACHOS.png",
    background:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/bg_nachos.png",
    background_mobile: "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/bgm_nachos.png",
    subtitle: "poco picante",
    spicyLine: "SpicyLine1",
    ingredients:
      "Harina de Maíz Nixtamalizado, Aceite Vegetal, Sazonador (Maltodextrina, Sólidos de la Leche, Saborizantes Naturales y Artificiales, Sal Yodada, Ácido Cítrico, Glutamato Monosódico, Especias, Queso, Caramelo Clase III, Caramelo Clase IV, Dióxido de Silicio, Amarillo 6, Amarillo 5) Rojo 40, Amarillo 6, Amarillo 5. Contiene Leche.",
    gramaje: "52g, 40g",
    nutritionalTable: {
      energetico_envase: [
        "52g 250 kcal (1048.8 kJ) por 100g",
        "40g 190 kcal (806.8 kJ) por 100g",
      ],
      contenido_energetico: "480 kcal (2017 kJ)",
      proteinas: "6g",
      grasas_totales: "32g",
      grasas_saturadas: "15g",
      grasas_trans: "0mg",
      hidratos_carbono: "43g",
      azucares: "0g",
      azucar_anadido: "0g",
      fibra_dieta: "7g",
      sodio: "850mg",
    },
    recipes: [2, 10],
  },
  {
    name: "SALSA PICANTE",
    description:
      "Algo picosito para un momento de antojo, definitivamente nuestra versión Salsa Picante es ideal para agregarle ese spicy a tu momento más Top.",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/salsa_picante.png",
    imageSlide:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Home/Productos/251024_TT-SALSA-PICANTE.png",
    background:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/bg_salsa_picante.png",
    background_mobile: "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/bgm_salsa_picante.png",
    subtitle: "picante",
    spicyLine: "SpicyLine1",
    ingredients:
      "Harina de Maíz Nixtamalizado, Aceite Vegetal, Sazonador (Maltodextrina, Sal Yodada, Glutamato Monosódico, Diacetato de Sodio, Ácido Cítrico, Chile en Polvo, Especias, Dióxido de Silicio, Inosinato de Sodio, Guanilato de Sodio, Saborizantes Naturales, Amarillo 6, Rojo 40), Rojo 40, Amarillo 6, Amarillo 5.",
    gramaje: "52g",
    nutritionalTable: {
      energetico_envase: [
        "52g 240 kcal (1 021.8 kJ) por 100g",
      ],
      contenido_energetico: "461 kcal (1928 kJ)",
      proteinas: "6g",
      grasas_totales: "26g",
      grasas_saturadas: "11g",
      grasas_trans: "0mg",
      hidratos_carbono: "53g",
      azucares: "0g",
      azucar_anadido: "0g",
      fibra_dieta: "11g",
      sodio: "540mg",
    },
    recipes: [],
  },
  {
    name: "SALSA NEGRA",
    description:
      "Una mezcla de increíbles sabores dan como resultado la vibrante combinación de Salsa Negra de La Costeña y ahora puedes disfrutar de una forma TOP.",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/salsa_negra.png",
    imageSlide:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Home/Productos/251024_TT-HOT-SALSA-NEGRA.png",
    background:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/bg_salsa_negra.png",
    background_mobile: "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/bgm_salsa_negra.png",
    subtitle: "picante",
    spicyLine: "SpicyLine2",
    ingredients:
      "Harina de Maíz Nixtamalizado, Aceite Vegetal, Sazonador (Maltodextrina, Sal Yodada, Glutamato Monosódico, Almidón Modificado, Ácido Cítrico, Saborizantes Naturales e Idénticos a los Naturales, Ácido Acético, Chile en Polvo, Especias, Proteína Vegetal Hidrolizada de Maíz, Inosinato de Sodio, Guanilato de Sodio, Rojo 40, Amarillo 6, Dióxido de Silicio, Azul 1), Caramelo Clase lll.",
    gramaje: "52g, 40g, 23g,",
    nutritionalTable: {
      energetico_envase: [
        "52g 240 kcal (987.4 kJ) por 100g",
        "40g 180 kcal (759.6 kJ) por 100g",
        "23g 100 kcal (436.7 kJ) por 100g",
      ],
      contenido_energetico: "450 kcal (1899 kJ)",
      proteinas: "7g",
      grasas_totales: "21g",
      grasas_saturadas: "8g",
      grasas_trans: "120mg",
      hidratos_carbono: "59g",
      azucares: "1g",
      azucar_anadido: "0g",
      fibra_dieta: "8g",
      sodio: "1190mg",
    },
    recipes: [9, 1, 5],
  },
  {
    name: "HOT CHILI",
    description:
      "Si te gusta lo picosito, tienes que probar nuestra variedad Hot Chili, la forma más rica de enchilarte al estilo TOP.",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/hot_chili.png",
    imageSlide:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Home/Productos/251024_TT-HOT-CHILI.png",
    background:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/bg_hot_chili.png",
    background_mobile: "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/bgm_hot_chili.png",
    subtitle: "muy picante",
    spicyLine: "SpicyLine2",
    ingredients:
      "Harina de Maíz Nixtamalizado, Aceite Vegetal, Sazonador (Maltodextrina, Sal Yodada, Saborizantes Naturales y Artificiales, Mezcla de Chiles, Ácido Cítrico, Ácido Málico, Rojo 40, Glutamato Monosódico, Inosinato de Sodio, Gualinato de Sodio, Bicarbonato de Sodio, Diacetato de Sodio, Aceite Vegetal, Especias, Dióxido de Silicio, Silicato de Calcio, Caramelo Clase III, Amarillo 6, Amarillo 5), Rojo 40, Amarillo 6, Amarillo 5.",
    gramaje: "52g, 40g",
    nutritionalTable: {
      energetico_envase: [
        "52g 250 kcal (1029 kJ) por 100g",
        "40g 190 kcal (791.6 kJ) por 100g",
      ],
      contenido_energetico: "470 kcal (1979 kJ)",
      proteinas: "6g",
      grasas_totales: "25g",
      grasas_saturadas: "11g",
      grasas_trans: "110mg",
      hidratos_carbono: "56g",
      azucares: "0g",
      azucar_anadido: "0g",
      fibra_dieta: "8g",
      sodio: "1060mg",
    },
    recipes: [4, 7, 8, 9],
  },
  {
    name: "EXTREME",
    description:
      "¿Te atreves a probar el sabor intenso y delicioso de nuestra versión Extreme? No apto para tod@s, solo para los más Tops.",
    image:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/extreme.png",
    imageSlide:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Home/Productos/251024_TT-EXTREME.png",
    background:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/bg_extreme.png",
    background_mobile:
      "https://assets-top-costena.s3.us-east-1.amazonaws.com/Productos/bgm_extreme.png",
    subtitle: "extra picante",
    spicyLine: "SpicyLine2",
    ingredients:
      "Harina de Maíz Nixtamalizado, Aceite Vegetal, Sazonador (Maltodextrina, Sal Yodada, Saborizantes Naturales y Artificiales, Mezcla de Chiles, Ácido Cítrico, Ácido Málico, Rojo 40, Glutamato Monosódico, Inosinato de Sodio, Guanilato de Sodio, Bicarbonato de Sodio, Diacetato de Sodio, Aceite Vegetal, Especias, Dióxido de Silicio, Silicato de Calcio, Caramelo Clase III, Amarillo 6, Amarillo 5), Rojo 40, Amarillo 6, Amarillo 5.",
    gramaje: "52g",
    nutritionalTable: {
      energetico_envase: [
        "52g 250 kcal (1 029 kJ) por 100g",
      ],
      contenido_energetico: "450 kcal (1899 kJ)",
      proteinas: "7g",
      grasas_totales: "25g",
      grasas_saturadas: "11g",
      grasas_trans: "0mg",
      hidratos_carbono: "64g",
      azucares: "0g",
      azucar_anadido: "0g",
      fibra_dieta: "8g",
      sodio: "1060mg",
    },
    recipes: [],
  },
];
