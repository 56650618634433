import { Box, Typography, Grid2 as Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import ParallaxImage from '../../../../components/ParallaxImage/parallax-image';
import { useEasterEgg } from '../../../../components/EasterEgg/easter-egg';
import VideoHero from '../../../../components/VideoHero/video-hero';
import Progress from '../../../../components/Progress/progress';
import { recipes as MockedRecipes } from '../utils/utils';

import TimeIcon from '../../../../assets/images/time.png';
import PorcionesIcon from '../../../../assets/images/porciones.png';
import DifficultyIcon from '../../../../assets/images/difficulty.png';
import TriangleDotted from "../../../../assets/images/triangles/triangle_dotted_upLeft.png";

import './recipe-details.css';

const RecipeDetails = () => {
  const [recipes, setRecipes] = useState([]);
  const [currentRecipe, setCurrentRecipe] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const { handleToggleBackdrop } = useEasterEgg();

  const mockFetchRecipes = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(MockedRecipes);
      }, 800);
    });
  };

  useEffect(() => {
    mockFetchRecipes().then((data) => {
      setRecipes(data);
      setIsLoading(false)
    });
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const validRecipe = recipes.find((recipe) => `receta-${recipe.id}` === id);
      if (!id || !validRecipe) {
        navigate('/');
        return;
      }
      setCurrentRecipe(validRecipe);
    }
  }, [id, navigate, isLoading, recipes]);

  if (!currentRecipe) {
    return <Progress />;
  }

  const handleImageClick = (index) => {
    handleToggleBackdrop(index); 
  };

  return (
    <Box className="container">
      {currentRecipe.video && <VideoHero videoSource={currentRecipe.video} />}
      <Box className="recipe-detail-section">
        <Grid container className="recipe-content" spacing={2}>
          {/* Sección título y descripción de la receta */}
          <Grid item xs={12} md={6} className="details-section">
            <ParallaxImage
              containerClass="left top"
              imgClass="triangle-center zoom"
              imageSrc={TriangleDotted}
              strength={0.01}
              lerpEase={0.10}
              mouseEffect={true}
              handleClick={() => handleImageClick(3)}
            />
            <Typography variant="h3" className="recipe-title">
              {currentRecipe.name}
            </Typography>
            <Typography variant="body1" className="recipe-descrip">
              {currentRecipe.description}
            </Typography>

            {/* Iconos y datos adicionales */}
            <Box className="extra-info">
              <Box className="info-item">
                <img src={TimeIcon} alt="Tiempo de preparación" className="info-icon" />
                <Typography variant="body1">{currentRecipe.duration}</Typography>
              </Box>
              <Box className="info-item">
                <img src={PorcionesIcon} alt="Porciones" className="info-icon" />
                <Typography variant="body1">{currentRecipe.portions}</Typography>
              </Box>
              <Box className="info-item">
                <img src={DifficultyIcon} alt="Dificultad" className="info-icon" />
                <Typography variant="body1">Dificultad: {currentRecipe.difficulty}</Typography>
              </Box>
            </Box>
          </Grid>

          {/* Sección de imagen */}
          <Grid item xs={12} md={6} className="details-section">
            <img src={currentRecipe.image} alt='Product' className="product-image" />
          </Grid>
        </Grid>

        {/* Sección de ingredientes y preparación */}
        <Grid container className="recipe-steps" spacing={2}>
          <Grid item xs={12} md={6} className="section-steps">
            <Typography variant="h4" className="section-title">Ingredientes</Typography>
            <ul className="ingredients-list">
              {currentRecipe.ingredients.map((ingredient) => <li>{ingredient}</li>)}
            </ul>
          </Grid>
          <Grid item xs={12} md={6} className="section-steps">
            <Typography variant="h4" className="section-title">Preparación</Typography>
            <ol className="preparation-list">
              {currentRecipe.preparation.map((step) => <li>{step}</li>)}
            </ol>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default RecipeDetails;
