import { AppBar, Toolbar, Box, Grid2 as Grid, IconButton, Button, Drawer, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Link } from 'react-router-dom';
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/images/Logo_Toptops.png";
import MenuMobile from "../../assets/images/menu-mobile.png";
import "./header.css";

function Header({ onSectionClick }) {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("PONTE TOP");
  const [isMobileOpen, setIsMobileOpen] = useState(false);


  const handleSectionClick = (section) => {
    setActiveSection(section);
    setIsMobileOpen(false);

    if (section === "CONTACTO") {
      navigate('/contacto');
    } else {
      const sectionMap = {
        "PONTE TOP": "experiences",
        "TOP EXPERIENCIAS": "experiences",
        "VARIEDADES": "products",
        "RECETOPS": "recipes",
      };
      if (window.location.pathname !== '/') {
        navigate('/');
        setTimeout(() => {
          onSectionClick(sectionMap[section]);
        }, 100);
      } else {
        onSectionClick(sectionMap[section]);
      }
    }
  };

  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  const toggleDrawer = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  const menuSections = ["PONTE TOP", "TOP EXPERIENCIAS", "VARIEDADES", "RECETOPS", "CONTACTO"];

  return (
    <AppBar position="sticky">
      <Toolbar className="header">
        {/* Mobile Menu Icon */}
        <Box sx={{
          display: { xs: 'flex', md: 'none' },
          '@media (min-width: 900px) and (max-width: 990px)': { display: 'flex' },
        }}>
          <img src={MenuMobile} alt="Menú" title="Menú" onClick={toggleDrawer} className="menu-mobile-img" />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Link
            to="/"
            onClick={(e) => {
              if (window.location.pathname === '/') {
                // Evita la navegación y haz scroll a la parte superior
                e.preventDefault();
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }
            }}
          >
            <IconButton edge="start" color="inherit" aria-label="logo">
              <img src={Logo} alt="Logo Top Top's" className="header logo" />
            </IconButton>
          </Link>
        </Box>
        {/* Desktop Menu */}
        <Box sx={{ flexGrow: 2 }} className="menu">
          <Grid container justifyContent="center" alignItems="center" spacing={4}>
            {["PONTE TOP", "TOP EXPERIENCIAS", "VARIEDADES", "RECETOPS", "CONTACTO"].map((section) => (
              <Grid item key={section}>
                <Button
                  color="inherit"
                  onClick={() => handleSectionClick(section)}
                  style={{ position: "relative" }}
                >
                  {section}
                  {activeSection === section && (
                    <div className="svg-container">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="137"
                        height="90"
                        viewBox="0 0 137 90"
                        fill="none"
                        className="section-svg"
                      >
                        <g filter="url(#filter0_di_1_98)">
                          <path
                            d="M106 16H31L37.457 21H100.04L106 16Z"
                            fill="#FDE003"
                          />
                        </g>
                        <ellipse
                          cx="68.5"
                          cy="66"
                          rx="68.5"
                          ry="24"
                          fill="url(#paint0_radial_1_98)"
                        />
                        <defs>
                          <filter
                            id="filter0_di_1_98"
                            x="15"
                            y="0"
                            width="107"
                            height="37"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feGaussianBlur stdDeviation="8" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0.992157 0 0 0 0 0.878431 0 0 0 0 0.0117647 0 0 0 0.86 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1_98"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1_98"
                              result="shape"
                            />
                          </filter>
                          <radialGradient
                            id="paint0_radial_1_98"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(68.5 66) rotate(88.6028) scale(20.5061 58.5278)"
                          >
                            <stop stopColor="#FDE003" stopOpacity="0.38" />
                            <stop offset="0.455" stopColor="#FDE003" stopOpacity="0.22" />
                            <stop offset="1" stopColor="#FDE003" stopOpacity="0" />
                          </radialGradient>
                        </defs>
                      </svg>
                    </div>
                  )}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* Right content */}
        <Box sx={{ flexGrow: 1 }} display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
          <IconButton className="social-icon facebook" onClick={() => handleClick('https://www.facebook.com/toptops.mx?mibextid=LQQJ4d')} />
          {/*<IconButton className="social-icon tiktok" onClick={() => handleClick('https://www.tiktok.com/@top.tops.mx?_t=8p7alDYhH7e&_r=1')} />*/}
          <IconButton className="social-icon youtube" onClick={() => handleClick('https://www.youtube.com/@toptopsmx')} />
          <IconButton className="social-icon instagram" onClick={() => handleClick('https://www.instagram.com/toptops.mx/')} />
        </Box>
        {/* Mobile Drawer */}
        <Drawer anchor="left" open={isMobileOpen} onClose={toggleDrawer}>
          <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer} className="menu-mobile-options">
            <Box sx={{ flexGrow: 1 }} className="menu-mobile-logo">
              <Link to="/">
                <IconButton edge="start" color="inherit" aria-label="logo">
                  <img src={Logo} alt="Logo Top Top's" className="header logo logo--mobile" />
                </IconButton>
              </Link>
            </Box>
            <List>
              {menuSections.map((section) => (
                <ListItem key={section} disablePadding>
                  <ListItemButton onClick={() => handleSectionClick(section)}>
                    <ListItemText primary={section} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
