import { Box, CircularProgress } from "@mui/material";

import "./progress.css";

function Progress(props) {
  return (
    <Box className="progress-container" sx={{ display: 'flex', justifyContent: 'center', margin: 5 }}>
      <CircularProgress className="progress-component" {...props} />
    </Box>
  )
}

export default Progress;
