import Divider from './../../assets/images/divider.png';
import { Box } from "@mui/material";

import "./divider.css";

function ImageDivider() {
  return (
    <Box className='divider-container'>
      <img className='divider-img' src={Divider} alt='' title='' />
    </Box>
  )
}

export default ImageDivider;
