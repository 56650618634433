import "./App.css";
import Landing from "./layouts/landing";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import CustomTheme from "./utils/theme";
import ProductDetails from './layouts/Content/Products/details'
import RecipeDetails from './layouts/Content/Recipes/details'
import Contacto from './layouts/Content/Contact'
import Header from "./layouts/Header";
import { Box, Container } from "@mui/material";
import MarkFooter from "./components/MarkFooter";
import Footer from "./layouts/Footer";
import ScrollToTop from "./components/ScrollTop/scroll-top";
import { useRef } from 'react';

function App() {
  const experiencesRef = useRef(null);
  const productsRef = useRef(null);
  const recipesRef = useRef(null);
  const animationRef = useRef(null);

  const refs = {
    experiences: experiencesRef,
    products: productsRef,
    recipes: recipesRef,
    animation: animationRef,
  };

  const scrollToSection = (section) => {
    if (refs[section] && refs[section].current) {
      refs[section].current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <ThemeProvider theme={CustomTheme}>
      <Container maxWidth="xl" className="main-container" disableGutters>
        <BrowserRouter>
          <ScrollToTop />
          <Header onSectionClick={scrollToSection} />
          <Routes>
            <Route path="/" exact element={<Landing refs={refs} />} />
            <Route path="/productos" exact element={<ProductDetails />} >
              <Route path=":id" element={<ProductDetails />} />
            </Route>
            <Route path="/recetas" exact element={<RecipeDetails />} >
              <Route path=":id" element={<RecipeDetails />} />
            </Route>
            <Route path="/contacto" exact element={<Contacto />} ></Route>
          </Routes>
          <Box className="footer-wrapper">
            <MarkFooter />
            <Footer onSectionClick={scrollToSection} />
          </Box>
        </BrowserRouter>
      </Container>
    </ThemeProvider>
  );
}

export default App;
