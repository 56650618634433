import { Box, Grid2 as Grid, Link, Button } from '@mui/material'

import TopanosImage from "../../../assets/images/topanos.png"
import PonteTopImage from "../../../assets/images/ponte_top.png"
import Triangle from "../../../assets/images/triangles/triangle_upLeft.png";
import TriangleUpRight from "../../../assets/images/triangles/triangle_upRight.png";
import TriangleDotted from "../../../assets/images/triangles/triangle_dotted_left.png";

import './experiences.css';
import ParallaxImage from "../../../components/ParallaxImage/parallax-image";
import { useEasterEgg } from '../../../components/EasterEgg/easter-egg';
import ImageDivider from '../../../components/Divider/divider';

function Experiences() {
  const { handleToggleBackdrop } = useEasterEgg();

  const handleImageClick = (index) => {
    handleToggleBackdrop(index); 
  };
  return (
    <Box className="experience-section">
      <ParallaxImage
        containerClass="center"
        imgClass="triangle-center zoom"
        imageSrc={TriangleDotted}
        strength={0.01}
        lerpEase={0.10}
        mouseEffect={true}
        handleClick={() => handleImageClick(0)}
      />
      <Box className="title">TOP EXPERIENCIAS</Box>
      <Grid container spacing={2} className="sections">
        <Grid size={6} className="image-section">
          <Link target="_self" className="sub-section">
            <img src={TopanosImage} className="img-experience" alt="Encuentranos aquí" />
            <Button variant="outlined" className="experience-button">TOPANOS</Button>
          </Link>
          <ParallaxImage containerClass="left" imgClass="triangle-left" strength={-0.15} lerpEase={0.10} imageSrc={Triangle} />
        </Grid>
        <Grid size={6} className="image-section">
          <Link target="_self" className="sub-section">
            <img src={PonteTopImage} className="img-experience" alt="Música, games y más!!" />
            <Button variant="outlined" className="experience-button experience-button--white">PONTE TOP</Button>
          </Link>
          <ParallaxImage containerClass="right" imgClass="triangle-right" imageSrc={TriangleUpRight} />
        </Grid>
      </Grid>
      <ImageDivider />
    </Box>
  );
}

export default Experiences;