export const messages = [
  "🎉 ¡Wow! ¡Has encontrado un TopTop! 😎",
  "😄 ¡Estupendo! Ya casi encuentras todos los TopTop's 🎯",
  "🔥 ¡Increíble! Sigues encontrando más TopTop's! 🚀",
  "🎯 ¡Buen trabajo! Estás muy cerca de completar todos los TopTop's 🏆",
  "🔥 ¡Te estás convirtiendo en un cazador de TopTop's profesional! 🌟",
  "💪 ¡Espectacular! Otro TopTop agregado a tu lista 🏆",
  "🚀 ¡Sigue así! Cada vez estás más cerca de encontrar todos los TopTop's 💫",
  "👏 ¡Genial! Este TopTop estaba bien escondido, ¡pero lo encontraste! 🔍",
  "🏆 ¡Gran logro! Has desenterrado otro TopTop legendario 👑",
];

export const successMessages = [
  "👏 ¡Impresionante! ¡Has encontrado todos los TopTop's escondidos! 🎉",
  "🥳 ¡Felicidades! ¡Completaste el desafío y encontraste cada TopTop! 🎊",
  "✨ ¡Lo lograste! ¡Todos los TopTop's han sido descubiertos, eres un campeón! 🏆",
  "🎉 ¡Asombroso! ¡Has revelado todos los secretos! 💫",
  "🌟 ¡Wow! Todos los TopTop's fueron encontrados, ¡eres increíble! 🙌",
  "🎈 ¡Bravo! Has completado la misión, cada TopTop está en tus manos! 👏",
  "¡Maravilloso! No dejaste ni un TopTop sin encontrar. ¡Eres el mejor! 🥇💥",
  "🎉 ¡Victoria! Has descubierto cada rincón y cada TopTop escondido. 💪",
  "🥳 ¡Enhorabuena! ¡Todos los TopTop's son tuyos, misión cumplida! 🎉",
  "🏅 ¡Qué gran logro! Encontraste cada TopTop, ¡eres una estrella! 🌠",
  "¡Explorador experto! Todos los TopTop's han sido encontrados. ¡Qué talento! 🎊🌈",
];

const totalElements = 5;

export const getRandomMessage = (totalFound) => {
  let contentMessages = messages;
  if (totalFound === totalElements) {
    contentMessages = successMessages;
  }
  const randomIndex = Math.floor(Math.random() * contentMessages.length);
  return { text: contentMessages[randomIndex], total: `${totalFound}/${totalElements}`};
};
