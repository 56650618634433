// src/Productos/modal/product-modal.jsx
import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import './product-modal.css';

const ProductModal = ({ open, handleClose, type, content }) => {
  console.log(type)
  const isNutritional = type === 'nutritional';
  
  return (
    <Modal open={open} onClose={handleClose} className="product-modal">
      <Box className="modal-container">
        <Typography variant="h6" align="center" className="modal-title">
          {isNutritional ? 'TABLA NUTRIMENTAL' : 'INGREDIENTES'}
        </Typography>
        {isNutritional ? (
          <>
            <Box className="nutrition-table">
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body2" fontWeight="bold">
                  TAMAÑO DE LA PORCIÓN <br />
                  {content.energetico_envase.map((porcion, index) => (
                    <span key={index}>{porcion}<br /></span>
                  ))}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 0.5 }}>
                <Typography variant="body2">CONTENIDO ENERGÉTICO</Typography>
                <Typography variant="body2">{content.contenido_energetico}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 0.5 }}>
                <Typography variant="body2">PROTEÍNAS</Typography>
                <Typography variant="body2">{content.proteinas}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 0.5 }}>
                <Typography variant="body2">GRASAS TOTALES</Typography>
                <Typography variant="body2">{content.grasas_totales}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 0.5 }}>
                <Typography variant="body2" fontWeight="bold">GRASAS SATURADAS</Typography>
                <Typography variant="body2" fontWeight="bold">{content.grasas_saturadas}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 0.5 }}>
                <Typography variant="body2" fontWeight="bold">GRASAS TRANS</Typography>
                <Typography variant="body2" fontWeight="bold">{content.grasas_trans}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 0.5 }}>
                <Typography variant="body2">CARBOHIDRATOS DISPONIBLES</Typography>
                <Typography variant="body2">{content.hidratos_carbono}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 0.5 }}>
                <Typography variant="body2">AZÚCARES</Typography>
                <Typography variant="body2">{content.azucares}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 0.5 }}>
                <Typography variant="body2" fontWeight="bold">AZÚCARES AÑADIDOS</Typography>
                <Typography variant="body2" fontWeight="bold">{content.azucar_anadido}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 0.5 }}>
                <Typography variant="body2">FIBRA DIETÉTICA</Typography>
                <Typography variant="body2">{content.fibra_dieta}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 0.5 }}>
                <Typography variant="body2" fontWeight="bold">SODIO</Typography>
                <Typography variant="body2" fontWeight="bold">{content.sodio}</Typography>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box className="ingredients-table">
              <Typography variant="body2">
                  {content}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ProductModal;
