import { useTheme, useMediaQuery } from "@mui/material";

export const useCurrentBreakpoint = () => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // <=600px
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md")); // 600px - 900px
  const isDesktop = useMediaQuery(theme.breakpoints.up(1000)); // >=1000px

  let currentBreakpoint;

  if (isMobile) {
    currentBreakpoint = "mobile";
  } else if (isTablet) {
    currentBreakpoint = "tablet";
  } else if (isDesktop) {
    currentBreakpoint = "desktop";
  }

  return currentBreakpoint;
};
