import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material'
import { useCurrentBreakpoint } from '../../../../utils/breakpoints';
import Slider from '../../../../components/Carousel/carousel'
import BtnVer from '../../../../assets/images/Btn_VerMas.png';
import { recipes } from '../utils/utils';
import './recipes-slider.css';
import ParallaxImage from '../../../../components/ParallaxImage/parallax-image';
import TriangleDotted from '../../../../assets/images/triangles/triangle_dotted_upLeft.png';
import TriangleShadow from '../../../../assets/images/triangles/triangle_shadow_right.png';
import { useEasterEgg } from '../../../../components/EasterEgg/easter-egg';

const mockFetchRecipes = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(recipes);
    }, 800);
  });
};

function SliderRecipes() {
  const [recipes, setRecipes] = useState([]);
  const { handleToggleBackdrop } = useEasterEgg() || {};

  const seeMoreBtnActive = false;
  const breakpoint = useCurrentBreakpoint();
  let slidesToShow = 1;
  if (breakpoint === 'desktop') {
    slidesToShow = 3;
  }

  useEffect(() => {
    mockFetchRecipes().then((data) => {
      setRecipes(data);
    });
  }, []);

  const handleImageClick = (index) => {
    handleToggleBackdrop(index); 
  };

  return (
    <Box className="recipes-slider-container">
      <ParallaxImage
        containerClass="top"
        imgClass="triangle-top zoom"
        imageSrc={TriangleDotted}
        strength={0.15}
        lerpEase={0.20}
        handleClick={() => handleImageClick(1)}
      />
      <Box className="title">GALERÍA</Box>
      <Box className="recipes-slider">
        <Slider slidesToShow={slidesToShow} customClass='recipes' images={
          recipes.map(recipe => ({
            src: recipe.image,
            caption: recipe.name,
            route: `/recetas/receta-${recipe.id}`
          }))
        } />
      </Box>
      {seeMoreBtnActive && (<Box className="content-centered">
        <Button className='btn-vermas'>
          <img src={BtnVer} alt='' />
        </Button>
      </Box>)}
      <ParallaxImage containerClass="left" imgClass="triangle-left" imageSrc={TriangleShadow} strength={-0.35} lerpEase={0.20} />
      <ParallaxImage containerClass="right" imgClass="triangle-right" imageSrc={TriangleShadow} strength={0.15} lerpEase={0.60} />
    </Box>
  );
}

export default SliderRecipes;