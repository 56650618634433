import PlayArrowIcon from './../../assets/images/btn_play.png';
import PauseIcon from './../../assets/images/btn_pause.png';
import { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";

import "./video-hero.css";

function VideoHero({ videoSource }) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isEnded, setIsEnded] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const inactivityTimeout = useRef(null);

  const togglePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
      videoRef.current.muted = false;
    }
    setIsPlaying(!isPlaying);
    setIsEnded(false);
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
    setIsEnded(true); // Marca que el video ha terminado
    setIsButtonVisible(true);
  };

  const resetInactivityTimeout = () => {
    setIsButtonVisible(true);
    if (inactivityTimeout.current) clearTimeout(inactivityTimeout.current);
    inactivityTimeout.current = setTimeout(() => setIsButtonVisible(false), 2000);
  };

  const handleMouseMove = () => {
    if (isPlaying) resetInactivityTimeout();
  };

  // Limpia el timeout cuando el componente se desmonte
  useEffect(() => {
    return () => clearTimeout(inactivityTimeout.current);
  }, []);

  return (
    <Box className="video-container"
      onMouseMove={handleMouseMove}
      onMouseEnter={() => isPlaying && setIsButtonVisible(true)}
      onMouseLeave={() => isPlaying && setIsButtonVisible(false)}
      >
      <video ref={videoRef} width="100%" height="100%" autoPlay muted onEnded={handleVideoEnd} >
        <source src={videoSource} type="video/mp4" />
      </video>
      <button
        className={`play-pause-btn ${(!isPlaying || isEnded || isButtonVisible) ? 'always-visible' : ''}`}
        onClick={togglePlayPause}
      >
        <img
          src={isPlaying ? PauseIcon : PlayArrowIcon}
          alt={isPlaying ? "Pausa" : "Reproducir"}
          className="icon-image"
        />
      </button>
    </Box>
  )
}

export default VideoHero;
