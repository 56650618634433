import { Box, Grid2 as Grid, Typography } from '@mui/material'
import ParallaxImage from '../../../components/ParallaxImage/parallax-image';
import { useEasterEgg } from '../../../components/EasterEgg/easter-egg';

import TriangleDotted from "../../../assets/images/triangles/triangle_dotted_left.png";
import mapaSection from '../../../assets/images/mapa-contact.png'

import './contact.css';


function Contact() {
  const { handleToggleBackdrop } = useEasterEgg();

  const handleImageClick = (index) => {
    handleToggleBackdrop(index);
  };

  return (
    <Box className="contact-section">
      <Grid container className="contact-content" spacing={2}>
        <ParallaxImage
          containerClass="center"
          imgClass="triangle-center zoom"
          imageSrc={TriangleDotted}
          strength={0.01}
          lerpEase={0.10}
          mouseEffect={true}
          handleClick={() => handleImageClick(4)}
        />
        <Grid item xs={12} md={6} className="map-section">
          <Typography variant="h3" className="contact-title">
            Aquí estamos para ayudarte
          </Typography>
          <img src={mapaSection} className="map-image" title="Mapa" alt="ubicación" />
        </Grid>
        <Grid item xs={12} md={6} className="address-section">
          <p><strong>PEDIDOS NACIONALES</strong><br />
            <span>800 201 7333</span>
          </p>
          <p>
            <strong>PEDIDOS INTERNACIONALES</strong><br />
            <span>+52 55 58 36 36 36 </span>
          </p>
          <p>
            4 SUR 123, CIUDAD INDUSTRIAL TIZAYUCA, HIDALGO<br />
            CÓDIGO POSTAL 43800, MÉXICO
          </p>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Contact;