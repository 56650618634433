import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material'
import ParallaxImage from '../../../../components/ParallaxImage/parallax-image';
import SliderProducts from '../../../../components/Carousel/carousel'
import { useCurrentBreakpoint } from '../../../../utils/breakpoints';
import BtnVer from '../../../../assets/images/Btn_VerMas.png';
import TriangleUpLeft from '../../../../assets/images/triangles/triangle_upLeft.png';
import TriangleLeftShadow from '../../../../assets/images/triangles/triangle_shadow_left.png';
import { products } from '../utils/utils';

import './products-slider.css';

const mockFetchProducts = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(products);
    }, 800);
  });
};

function Products() {
  const [products, setProducts] = useState([]);
  const seeMoreBtnActive = false;
  const breakpoint = useCurrentBreakpoint();
  let slidesToShow = 1;

  if (breakpoint === 'desktop') {
    slidesToShow = 5;
  }

  // Efecto para simular la petición a la API y cargar los productos
  useEffect(() => {
    mockFetchProducts().then((data) => {
      setProducts(data);
    });
  }, []);

  return (
    <Box className="products-slider-container">
      <Box className="products-slider">
        <SliderProducts customClass="products" slidesToShow={slidesToShow} images={
          products.map(product => ({
            src: product.imageSlide,
            caption: "",
            route: `/productos/${product.name.toLowerCase().replace(/\s+/g, '-')}`
          }))
        } />
      </Box>
      {seeMoreBtnActive && (<Box className="content-centered">
        <Button className='btn-vermas'>
          <img src={BtnVer} alt='' />
        </Button>
      </Box>)}
      <ParallaxImage containerClass="top" imgClass="triangle-top" imageSrc={TriangleUpLeft} strength={0.25} lerpEase={0.80} />
      <ParallaxImage containerClass="top right" imgClass="triangle-right" imageSrc={TriangleLeftShadow} strength={-0.15} lerpEase={0.50} />
    </Box>
  );
}

export default Products;