import { Container } from '@mui/material';

import './landing.css';
import VideoHero from '../components/VideoHero/video-hero';
import Experiences from './Content/Experiences/experiences';
import SliderProducts from './Content/Products/slider';
import SliderRecipes from './Content/Recipes/slider';
import AnimatedBowl from '../components/AnimatedBowl/animated-bowl';

function Landing({ refs }) {
  return (
    <Container maxWidth="xl" className="landing-container" disableGutters>
      <VideoHero videoSource="https://assets-top-costena.s3.amazonaws.com/Home_TopTops.mp4" />
      <div ref={refs.experiences}>
        <Experiences />
      </div>
      <div ref={refs.animation}>
        <AnimatedBowl />
      </div>
      <div ref={refs.products}>
        <SliderProducts />
      </div>
      <div ref={refs.recipes}>
        <SliderRecipes />
      </div>
    </Container>
  );
}

export default Landing;