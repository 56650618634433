import { ScrollParallax, MouseParallax } from "react-just-parallax";
import { Box } from "@mui/material";
import React from "react";

import "./parallax-image.css";

function ParallaxImage(props) {
  const { containerClass, imgClass, strength, lerpEase, imageSrc, isAbsolutelyPositioned, isHorizontal, mouseEffect, handleClick } =
    props;

  if (mouseEffect) {
    return (
      <Box className={`parallax-image-container ${containerClass}`}>
        <MouseParallax strength={strength}
          lerpEase={lerpEase}
          isAbsolutelyPositioned={isAbsolutelyPositioned}
        >
          <img src={imageSrc} className={imgClass} alt="" title="" onClick={handleClick} />
        </MouseParallax>
      </Box>
    );
  }
  return (
    <Box className={`parallax-image-container ${containerClass}`}>
      <ScrollParallax
        strength={strength}
        lerpEase={lerpEase}
        isAbsolutelyPositioned={isAbsolutelyPositioned}
        isHorizontal={isHorizontal}
      >
        <img src={imageSrc} className={imgClass} alt="" title="" onClick={handleClick} />
      </ScrollParallax>
    </Box>
  );
}

export default ParallaxImage;
